import styled from "styled-components";

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  `

export const ReviewsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `

export const SectionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 16px;
    font-size: 20px;
    height: 80px;

    @media (max-width: 900px){
      height: auto;
      justify-content: center;
    }    
    
    
    @media (min-width: 654px){
      width: 600px;
    }    
    
    @media (min-width: 1394px) {
      width: 100%;
    }
    
    @media (max-width: 1393px){
      height: auto;
      justify-content: center;
    }
  `

export const BadgesWrapper = styled.div`
    display: flex;
    
    @media (max-width: 1393px) {
      justify-content: space-between;
      width: 465px;
    }
      
    @media (max-width: 500px) {
      justify-content: center;
      flex-wrap: wrap;
      width: 250px;
      gap: 10px;
    }
  `

export const ReviewsTitle = styled.h1`
    text-align: center;
    font-size: ${props => props.ru === 'ru' ? '28px' : '40px'};
    font-family: ${props => props.ru === 'ru' ? 'Raleway' : 'Italianno'};
  `

export const StyledWrapper = styled.div`
    border-radius: 8px;
    font-family: 'Roboto';
    width: 435px;
    border: 3px solid #edd0a1;
    padding: 16px;
    line-height: 1.35;
    color: white;
    margin: 12px;
    
    @media (max-width: 500px) {
      width: 100%;
      margin: 6px;
    }
  `

export const ReviewerName = styled.h1`
    font-size: 21px;
    margin-left: 12px;
  `

export const ReviewDescription = styled.h2`
    font-size: 18px;
    color: #fff;
    text-align: left;
  `

export const ReviewHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `

export const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
  `

export const BookingRating = styled.div`
    background-color: #003580;
    width: 32px;
    height: 32px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 8px 8px 8px 0;
  `