import React, { useState } from 'react'
import { ReservationSystem, ReservationButton, ReservationWrapper } from "../../assets/styles";
import { StaticImage } from "gatsby-plugin-image";
import {useIntl} from "gatsby-plugin-intl";

export const Reservation = () => {
  const [isVisible, setVisibility] = useState(false)

  const intl = useIntl();
  return (
    <div>
      <ReservationWrapper onClick={() => setVisibility(!isVisible)}>
        <StaticImage quality={100} width={35} height={35} src='../../assets/images/calendar.png' alt="Calendar icon" />
        <ReservationButton>{intl.formatMessage({ id: "reservationButton" })}</ReservationButton>
      </ReservationWrapper>

      {isVisible && (
        <ReservationSystem src="https://engine10381.idobooking.com/widget/booking/defaultchoice/currency/0/language/0&transparentbackground=1"/>
      )}
    </div>
  )
}