import React from "react"
import { useIntl } from "gatsby-plugin-intl";
import { ServicesWrapper, FirstColumn, SecondColumn, ThirdColumn, SectionDescription,  } from '../../assets/styles'
import {StaticImage} from "gatsby-plugin-image";

export const Services = () => {
    const intl = useIntl();

    return (
        <ServicesWrapper>
            <FirstColumn>
              <StaticImage quality={100} style={{marginTop: '10px', marginBottom: '5px'}} width={90} height={100} src='../../assets/images/towel.png' alt="towel picture" />
                <SectionDescription>
                    {intl.formatMessage({ id: "servicesDescription" })}
                </SectionDescription>
            </FirstColumn>
            <SecondColumn>
              <StaticImage quality={100} width={140} height={115} src='../../assets/images/dinner2.png' alt="dinner picture" />
                <SectionDescription>
                    {intl.formatMessage({ id: "servicesDescription2" })}
                </SectionDescription>
            </SecondColumn>
            <ThirdColumn>
              <StaticImage quality={100} width={130} height={115} src='../../assets/images/saltMine.png' alt="kp salt mine logo" />
                <SectionDescription>
                    {intl.formatMessage({ id: "servicesDescription3" })}
                </SectionDescription>
            </ThirdColumn>
        </ServicesWrapper>
    )
}