import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import { Gallery } from '../../assets/styles'
import { StaticImage } from "gatsby-plugin-image";

const options = {
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showThumbnailsButton: true
    },
    caption: {
        showCaption: false,
    }
};

export const Photos = () => {

    return (
        <SRLWrapper options={options}>
            <Gallery>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/0000.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/00.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/00b.jpg' alt="KP map"/>

                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/00c.jpg' alt="KP map"/>
                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/00d.jpg' alt="KP map"/>

                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/02.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/02a.jpg' alt="KP map"/>


                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/02c.jpg' alt="KP map"/>


                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/03.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/03a.jpg' alt="KP map"/>

                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/03b.jpg' alt="KP map"/>

                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/03c.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/03d.jpg' alt="KP map"/>


                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/4.jpg' alt="KP map"/>
                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/05a.jpg' alt="KP map"/>
                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/06.jpg' alt="KP map"/>
                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/06a.jpg' alt="KP map"/>


                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/06b.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/0010.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/0011.jpg' alt="KP map"/>
                <StaticImage quality={100} width={1499} height={1000} src='../../assets/images/0012.jpg' alt="KP map"/>


                <StaticImage quality={100} width={892} height={1080} src='../../assets/images/00a.jpg' alt="KP map"/>
            </Gallery>
        </SRLWrapper>
        )
  }