import React from "react"
import { SliderWrapper } from '../../assets/styles'
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";


export const Carousel = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 7000,
  }

  return (
    <SliderWrapper>
      <Slider {...settings}>
        <StaticImage quality={90} layout="fixed" width={1399} height={820} src='../../assets/images/slide01.jpg' alt="slider image" />
        <StaticImage quality={90} layout="fixed" width={1399} height={820} src='../../assets/images/slide02.jpg' alt="slider image" />
        <StaticImage quality={90} layout="fixed" width={1399} height={820} src='../../assets/images/slide03.jpg' alt="slider image" />
      </Slider>
    </SliderWrapper>
  );
}
const NextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width: "20px",marginRight:"40px", zIndex: "9999" }}
      onClick={onClick}
    />
  );
}

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width: "20px", marginLeft:"40px", zIndex:"9999"}}
      onClick={onClick}
    />
  );
}
