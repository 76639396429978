import styled from 'styled-components'
import { Link } from "gatsby-plugin-intl"
import Img from 'gatsby-image';

export const FooterNavigationItem = styled.li`
    margin-top:8px;
    width:175px;
    a {
        text-decoration: none;
        :hover{
            color: #fff;
        }
    }
  @media (max-width: 650px){
    width:130px
  }
`
export const FooterLink = styled(Link)`
`
export const BorderDarkFooter = styled.div`
    position:absolute !important;
    left:calc(50% - 60px);
    top: -2px;
`

export const FooterExtras = styled(Img)`
  width:100%;
  height:100%;
  max-width:1000px;
  margin: 0 auto;
`
export const ExternalLink = styled.a``

export const FooterTitle = styled.p`
    margin:5px 0 0 0;
    width:175px;
    font-size: ${props => props.ru === 'ru' ? '19px' : '29px'};
    font-family: ${props => props.ru === 'ru' ? 'Roboto' : 'Italianno'};
  @media (max-width: 650px){
    width:130px
  }
`

export const FooterContent = styled.p`
  color: #fff;
  margin: 12px 0 0 0;
  font-family: 'Roboto', cursive;
`
export const FooterContainer = styled.footer`
    display:grid;
    grid-template-rows: 1fr;
    grid-template-columns: 20px 1fr 1fr 1fr 1fr 20px;
    grid-gap: 15px;
    width:100%;
    height:100%;
    min-height: 150px;
    padding: 22px 0 12px 0;
    border-top: 2px solid #edd0a1;
    background-color: #5e5e5e;
    position: relative;

    @media (max-width: 760px){
      grid-template-rows: 160px 125px;
      grid-template-columns: 5px 1fr 1fr 5px;
      row-gap: 45px;
      padding-bottom: 0;
    }
`

export const FooterNavigation = styled.ul`
    list-style:none;
    grid-column: 2/3;
    justify-self:center;
    font-size: ${props => props.ru === 'ru' ? '18px' : '29px'};
    font-family: ${props => props.ru === 'ru' ? 'Roboto' : 'Italianno'};
  @media (max-width: 1000px){
    justify-self:center;
  }
  @media (max-width: 760px){
    grid-column: 2/3;
    grid-row: 1/2;
  }
`

export const FooterContactWrapper = styled.div`
    grid-column: 3/4;
    justify-self:center;
  @media (max-width: 760px){
    grid-column: 3/4;
    grid-row: 2/3;
    justify-self: center;
  }
`

export const SocialMediaWrapper = styled.div`
  grid-column: 4/5;
  justify-self:center;
  @media (max-width: 760px){
    grid-column: 2/3;
    grid-row: 2/3;
    justify-self: center;
  }
`

export const FooterLogoWrapper = styled.div`
  grid-column: 5/6;
  place-self:center;
    @media (max-width: 1000px){
    justify-self:flex-end;
  }
  @media (max-width: 760px){
    grid-column: 3/4;
    grid-row: 1/2;
    justify-self: center;
    margin-top:32px;
  }
`