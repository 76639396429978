import styled from 'styled-components';

export const DescriptionWrapper = styled.section`
  margin-bottom: 60px;
  padding-top: 1px;
  color: #edd0a1;

  * {
    font-size: 24px;
    text-align:center;
    font-family: 'Raleway';
    line-height: 15px;
    font-weight: 200;
  }
  @media (max-width: 1000px) {
    * {
        font-size: 23px;
        line-height: 30px;
        padding: 0 10px;
    }

  }
`
export const ServicesWrapper = styled.section`
  background-color: #5e5e5e;
  height: 100%;
  border-top: 3px solid #edd0a1;
  border-bottom: 3px solid #edd0a1;
  padding-top: 45px;

  display: grid;
  grid-template-rows:1fr;
  grid-template-columns: 20px 1fr 1fr 1fr 20px;
  justify-items: center;
  grid-column-gap: 50px;

  @media (min-width: 2200px){
    grid-template-columns: 12% 1fr 1fr 1fr 12%;
  }
  @media (max-width: 1200px){
    grid-template-columns: 10px 1fr 1fr 1fr 10px;
    grid-column-gap: 24px;
  }
  @media (max-width: 1000px){
  grid-template-rows:1fr 1fr 1fr;
  grid-template-columns: 20px 1fr 20px;
  }
`

export const FirstColumn = styled.div`
  grid-column: 2/3;
  display:flex;
  flex-direction:column;
  align-items:center;
  max-width:500px;
  @media (max-width: 1000px){
    grid-column: 2/3 ;
    grid-row: 1 ;
  }
`

export const SecondColumn = styled.div`
  grid-column: 3/4;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width:500px;
  @media (max-width: 1000px){
    grid-column: 2/3 ;
    grid-row: 2;
  }
`

export const ThirdColumn = styled.div`
  grid-column: 4/5;
    display: flex;
  flex-direction: column;
  align-items: center;
  max-width:500px;
  @media (max-width: 1000px){
    grid-column: 2/3;
    grid-row: 3;
  }
`

export const SectionDescription = styled.h1`
    font-size: 18px;
    text-align: center;
    font-family: 'Raleway';
    color: #fff;
    font-weight: 200;
    margin: 42px 0 50px 0;

    @media (min-width: 2200px){
      font-size: 20px;
    }
`