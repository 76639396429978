import styled from 'styled-components';

export const CommunicationWrapper = styled.div`
  display: grid;
  grid-template-rows: 3fr;
  grid-template-columns: 100px 1fr;
  grid-gap: 30px;
  align-items:center;

  @media (max-width: 700px){
    grid-template-columns: 80px 1fr;
    grid-template-rows: 80px 80px 80px;
    grid-gap: 10px;
  }

  @media (max-width: 500px){
    grid-template-columns: 60px 1fr;
    grid-template-rows: 50px 50px 50px;
    grid-gap: 10px;
  }

  @media (max-width: 500px){
    grid-gap: 14px;
  }
`
export const ContactDetailsWrapper = styled.div`
    display:grid;
    grid-template-columns: 100px 1fr 100px 1fr;
    grid-gap: 30px;
    align-items:center;

    @media (max-width: 700px){
      grid-template-columns: 80px 1fr;
      grid-template-rows: 80px 80px 80px 80px;
      grid-gap: 10px;
    }

    @media (max-width: 500px){
      grid-template-columns: 60px 1fr;
      grid-template-rows: 50px 50px 50px 50px;
      grid-gap: 10px;
    }

    @media (max-width: 500px){
      grid-gap: 14px;
    }
`
export const ContactIconWrapper = styled.div`
  margin-left:30px;

  @media (max-width: 500px){
    margin-left:10px;
}
`
export const StyledText = styled.p`
  font-size: 22px;
  font-family: 'Raleway';
  font-weight: 200;
  color: #fff;

  @media (max-width: 700px){
    margin-left: 5%;
    font-size:21px;
  }

  @media (max-width: 500px){
    font-size: 19px;
  }
`

export const StyledLink = styled.a`
  font-size: 22px;
  font-family: 'Raleway';
  font-weight: 200;

  @media (max-width:700px){
    margin-left: 5%;
    font-size:21px;
  }

  @media (max-width: 500px){
    font-size: 19px;
  }
  `