import styled from 'styled-components'

export const OfferWrapper = styled.div`
        width:70%;
        margin: 60px 15%;
        border-left: 5px solid #edd0a1;

    @media (max-width: 1800px){
        width:86%;
        margin: 60px 7%;
        border-left: 5px solid #edd0a1;
    }

    @media (max-width: 900px){
        width: 96%;
        margin: 60px 2% 60px 2%;
    }
`;

export const OfferHeading = styled.h1`
    font-size: ${props => props.ru === 'ru' ? '30px' : '45px'};
    font-family: ${props => props.ru === 'ru' ? 'Roboto' : 'Italianno'};
    margin: ${props => props.ru ==='ru' ? "35px 0 30px 25px" : "20px 0 20px 25px"};

    @media (max-width: 900px){
        font-size: ${props => props.ru === 'ru' ? '25px' : '40px'};
        margin: ${props => props.ru === 'ru' ? "30px 0 10px 15px" : "15px 0 10px 15px"};
    }
`

export const OfferDescription = styled.p`
    font-family: 'Roboto';
    margin-left: 50px;
    font-size: 21px;
    margin-right:10px;
    color: #fff;

    @media (max-width: 900px){
        font-size: 19px;
        margin-left: 30px;
    }
`
export const ExtraDescription = styled.p`
    font-family: 'Roboto';
    margin-left: 70px;
    margin-right:10px;
    font-size: 19px;
    color: #fff;

    @media (max-width: 900px){
        font-size: 17px;
        margin-left: 45px;
    }
`
export const IconWrapper = styled.div`
    margin-left: 90px;

    @media (max-width: 900px){
        margin-left: 55px;
    }
`
export const InlineWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;

    @media (max-width: 900px){
        margin: 5px 0;
    }
`
export const OfferLink = styled.a`
    color: #edd0a1;
    font-family: 'Roboto';
    margin-left: 50px;
    font-size: 21px;

    @media (max-width: 900px){
        font-size: 19px;
        margin-left: 30px;
    }
`
export const SignatureWrapper = styled.div`
    font-size:48px;
    margin-top:30px;
    padding-bottom: 20px;
    text-align:center;
    width:100%;
    font-size: ${props => props.ru === 'ru' ? '28px' : '48px'};
    font-family: ${props => props.ru === 'ru' ? 'Roboto' : 'Italianno'};

    @media(max-width: 900px){
        font-size: ${props => props.ru === 'ru' ? '24px' : '38px'};
    }
`