import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing:inherit;
    }

    body {
        margin: 0;
        background-color: #212121;
        font-family: 'Italianno';
        color: #edd0a1;

        * {
            font-weight: 200;
        }
    }

    a {
        color: #edd0a1;
    }

    button {
        padding: 0;
        cursor:pointer;
    }

    ul {
        padding: 0;
        margin: 0;
    }
`;

export default GlobalStyle;