import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { ContactDetailsWrapper, ContactIconWrapper, StyledText, StyledLink, SectionHeading } from '../../assets/styles'
import { StaticImage } from "gatsby-plugin-image";

export const ContactDetails = () => {
  const intl = useIntl();

  return (
    <>
      <SectionHeading>{intl.formatMessage({ id: "contact_details" })}</SectionHeading>
      <ContactDetailsWrapper>
        <ContactIconWrapper>
          <StaticImage quality={100} width={40} height={40} src='../../assets/images/addressIco.png' alt="Address icon" />
        </ContactIconWrapper>
        <StyledText>ul. Ks. Z. Goliana 4, 32-020 Wieliczka</StyledText>
        <ContactIconWrapper>
          <StaticImage quality={100} width={40} height={40} src='../../assets/images/phone.png' alt="Phone icon" />
        </ContactIconWrapper>
        <StyledText>735 935 489</StyledText>
        <ContactIconWrapper>
          <StaticImage quality={100} width={40} height={40} src='../../assets/images/mailIco.png' alt="Email icon" />
        </ContactIconWrapper>
        <StyledLink href="mailto:kpclaudia@orange.pl">kpclaudia@orange.pl</StyledLink>
        <ContactIconWrapper>
          <StaticImage quality={100} width={40} height={40} src='../../assets/images/cardIco.png' alt="Credit card icon" />
        </ContactIconWrapper>
        <StyledText>48 1240 5080 1111 0000 5202 5169</StyledText>
      </ContactDetailsWrapper>
    </>
  )
}