import React, { useState } from 'react'
import { useIntl, Link } from "gatsby-plugin-intl"
import { NavigationWrapper, NavigationList, NavigationListItem, ImgWrapper, SmallLogo } from '../../assets/styles'
import { Burger, Languages } from '../index'
import { StaticImage } from "gatsby-plugin-image"

export const Navigation = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  return (
    <NavigationWrapper ru={intl.locale}>
      <Languages />
      <NavigationList withBurger>
        <Burger setToOpen={() => setOpen(!open)} />
        <SmallLogo>
          <Link to="/">
              <StaticImage quality={100} width={80} height={80} src='../../assets/images/kp-herb.png' alt="kp claudia logo" />
          </Link>
        </SmallLogo>
        {open &&
          <>
            <NavigationListItem onClick={() => setOpen(!open)}><Link to="/">{intl.formatMessage({ id: "home_page" })}</Link></NavigationListItem>
            <NavigationListItem onClick={() => setOpen(!open)}><Link to="/gallery">{intl.formatMessage({ id: "gallery_page" })}</Link></NavigationListItem>
            <NavigationListItem onClick={() => setOpen(!open)}><Link to="/reservation">{intl.formatMessage({ id: "offer_page" })}</Link></NavigationListItem>
            <NavigationListItem onClick={() => setOpen(!open)}><Link to="/contact">{intl.formatMessage({ id: "contact_page" })}</Link></NavigationListItem>
          </>
        }
      </NavigationList>

      <NavigationList ru={intl.locale}>
        <NavigationListItem><Link to="/">{intl.formatMessage({ id: "home_page" })}</Link></NavigationListItem>
        <NavigationListItem><Link to="/gallery">{intl.formatMessage({ id: "gallery_page" })}</Link></NavigationListItem>
        <NavigationListItem ru={intl.locale}>
            <Link to="/">
                <StaticImage quality={100} width={80} height={80} src='../../assets/images/kp-herb.png' alt="kp claudia logo" />
            </Link>
        </NavigationListItem>
        <NavigationListItem><Link to="/reservation">{intl.formatMessage({ id: "offer_page" })}</Link></NavigationListItem>
        <NavigationListItem><Link to="/contact">{intl.formatMessage({ id: "contact_page" })}</Link></NavigationListItem>
      </NavigationList>
        <ImgWrapper>
            <StaticImage quality={100} layout="fixed" width={120} height={32} src='../../assets/images/dark-b3.jpg' alt="decoration" />
        </ImgWrapper>
    </NavigationWrapper>
  )
}