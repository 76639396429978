import styled from "styled-components";

export const ReservationSystem = styled.iframe`
    border: none;
    max-width: 100%;
    width: 100%;
    height: 650px;
  `

export const ReservationButton = styled.button`
  font-family: 'Raleway';
  font-size: 32px;
  color: #edd0a1;
  background-color: transparent;
  border: none;
  font-weight: 200;
  margin-left: 20px;
  transition: width 0.5s ease-out 0.2s;

  @media (max-width: 600px) {
    font-size: 25px;
  }
`

export const ReservationWrapper = styled.div`
  width: 100%;
  border-bottom: 5px solid #edd0a1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  padding: 10px;
  margin: 50px auto 20px auto;
  transition: 500ms;
  cursor: pointer;
  
  :hover {
    background-color: #5e5e5e;
    transition: 500ms;
  }
  
  @media (max-width: 600px) {
    width: 100%;
  }
`