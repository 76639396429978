import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
    min-height: 70vh;
    display:flex;
    flex-direction: column;
    padding-top:50px;
    padding-bottom:50px;
    text-align: center;
    align-items: center;

    h1 {
        font-size:40px;
    }
`