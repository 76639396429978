import styled from 'styled-components';

export const MapWrapper = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    margin-top:65px;
`
export const ContactContent = styled.div`
    width:80%;
    margin: 0 10%;
    h1 {
        font-size: ${props => props.ru === 'ru' ? '25px' : '42px'};
        font-family: ${props => props.ru === 'ru' ? 'Roboto' : 'Italianno'};
        margin:  ${props => props.ru === 'ru' ? '47px 0' : 'default'};
    }
    @media (max-width: 1000px){
        width:96%;
        margin: 0 2%;
    }
`

export const Map = styled.iframe`
    width:82%;
    min-height: 400px;
    height:47vh;
    border:8px solid #edd0a1;

    @media (max-width:1000px){
        width:90%;
    }

    @media (max-width: 760px){
        width:96%;
    }
`

export const KpMapWrapper = styled.div`
    display:flex;
    justify-content: center;
    margin: 20px auto 70px auto;
    max-width: 1800px;
`

export const SectionHeading = styled.h1``