import styled from 'styled-components'

export const SliderWrapper = styled.div`
  max-width: 1310px;
  overflow:hidden;
  border-top: 7px solid #edd0a1;
  border-left: 10px solid #edd0a1;
  border-right: 10px solid #edd0a1;
  margin:60px auto 40px auto;
  background-color: #edd0a1;

  @media(max-width: 1500px){
    max-width:1150px;
  }

  @media (max-width:1130px){
    margin: 50px 10px 30px 10px;
  }
`;