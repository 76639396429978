import React from 'react'
import { useIntl } from "gatsby-plugin-intl";
import { DescriptionWrapper } from '../../assets/styles'

export const HomeMessage = () => {
    const intl = useIntl();

    return(
        <DescriptionWrapper>
            <p>{intl.formatMessage({ id: "indexMessage" })}</p>
            <p>{intl.formatMessage({ id: "indexMessage2" })}</p>
            <p>{intl.formatMessage({ id: "indexMessage3" })}</p>
            <p>{intl.formatMessage({ id: "indexMessage4" })}</p>
        </DescriptionWrapper>
    )
}