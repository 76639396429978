import React from 'react'
import { useIntl } from "gatsby-plugin-intl";
import { ContactIconWrapper, StyledLink, CommunicationWrapper, SectionHeading } from '../../assets/styles'
import {StaticImage} from "gatsby-plugin-image";

export const Communication = () => {
    const intl = useIntl();

    return (
        <>
            <SectionHeading>{intl.formatMessage({ id: "communications" })}</SectionHeading>
            <CommunicationWrapper>
              <ContactIconWrapper>
                <StaticImage quality={100} width={40} height={40} src='../../assets/images/out.png' alt="Address icon" />
              </ContactIconWrapper>
                <StyledLink target="_blank" rel="noopener noreferrer" href="http://rozklady.mpk.krakow.pl/?lang=PL&linia=304">{intl.formatMessage({ id: "bus_link" })}</StyledLink>
              <ContactIconWrapper>
                <StaticImage quality={100} width={40} height={40} src='../../assets/images/out.png' alt="Address icon" />
              </ContactIconWrapper>
                <StyledLink target="_blank" rel="noopener noreferrer" href="https://malopolskiekoleje.pl">{intl.formatMessage({ id: "train_link" })}</StyledLink>
              <ContactIconWrapper>
                <StaticImage quality={100} width={40} height={40} src='../../assets/images/out.png' alt="Address icon" />
              </ContactIconWrapper>
                <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.google.com/destination/map/topsights?q=atrakcje+w+wieliczce&rlz=1C1GCEA_enPL826PL826&site=search&output=search&dest_mid=/m/0140sc&sa=X&ved=0ahUKEwivqbywg6zfAhVQp4sKHdHEA0UQ6tEBCDIoBDAA">{intl.formatMessage({ id: "attractions_link" })}</StyledLink>
            </CommunicationWrapper>
        </>

    )
}