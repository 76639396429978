import React from 'react'
import {Review} from "../Review/Review";
import {StaticImage} from "gatsby-plugin-image";
import {useIntl} from "gatsby-plugin-intl";
import {reviewsList} from "../../consts";
import { Wrapper, BadgesWrapper, ReviewsTitle, SectionTitle, ReviewsWrapper } from "../../assets/styles";

export const Reviews = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <SectionTitle>
        <ReviewsTitle ru={intl.locale}>{intl.formatMessage({ id: "reviewsTitle" })}</ReviewsTitle>
        <BadgesWrapper>
          <a style={{marginRight: '6px'}} target="_blank" rel="noopener noreferrer" href="https://www.booking.com/hotel/pl/claudia.pl.html#tab-reviews">
            <StaticImage quality={100} width={220} height={33} src='../../assets/images/bookingBadge.png' alt="booking badge"/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/travel/hotels/kpclaudia/entity/CgoIspvUuozExMJHEAE/reviews?q=kpclaudia&g2lb=2502548%2C2503771%2C2503781%2C2504097%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4597339%2C4718358%2C4723331%2C4754388%2C4757164%2C4814050%2C4816977%2C4826689%2C4850738%2C4852066%2C4856169%2C4856633%2C4856937&hl=pl-PL&gl=pl&cs=1&ssta=1&rp=ELKb1LqMxMTCRxCym9S6jMTEwkc4AkAASAHAAQI&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0NzE2NDFkYTczYWIwZTFmOjB4NDc4NTEyMjBjNzU1MGRiMhoAEhoSFAoHCOYPEAkYFhIHCOYPEAkYFxgBMgIQACoJCgU6A1BMThoA">
            <StaticImage quality={100} width={220} height={33} src='../../assets/images/googleBadge.png' alt="google badge"/>
          </a>
        </BadgesWrapper>
      </SectionTitle>
      <ReviewsWrapper>
        {reviewsList.map((review) => <Review review={review} />)}
      </ReviewsWrapper>
    </Wrapper>
  )
}
