import Img from 'gatsby-image';
import styled from 'styled-components';

export const NavigationWrapper = styled.nav`
    font-size: ${props => props.ru === 'ru' ? '20px' : '36px'};
    font-family: ${props => props.ru === 'ru' ? 'Roboto' : 'Italianno'};
    width: 100%;
    height: 110px;
    position: relative;
    background-color: #5e5e5e;
    border-bottom: 2px solid #edd0a1;

    @media (max-width: 780px) {
        font-size: ${props => props.ru === 'ru' ? '19px' : '32px'};
    }

    @media (max-width: 630px) {
        height: 100%;
    }
`
export const NavigationList = styled.ul`
    list-style: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: ${props => props.withBurger ? 'none' : 'flex'};
    padding-top: ${props => props.ru === 'ru' ? '0' : '15px'};
    li {
      cursor:pointer;
      
      :hover {
        border-bottom: 2px solid #edd0a1;
      }
    }
  
    @media (min-width: 780px) {
         li {
            margin: ${props => props.ru === 'ru' ? '40px 25px' : '0 30px'};
            height: ${props => props.ru === 'ru' ? '35px' : '100%'};
                :nth-child(3){
                    margin-top: ${props => props.ru === 'ru' ? "-40px" : "0px"};
                    :hover{
                        border-bottom: 2px solid #5e5e5e;
                    }
                }
        }
    }

    @media (min-width: 647px) and (max-width: 807px) {
      li {
        margin: ${props => props.ru === 'ru' ? '40px 15px' : '0 10px'};
        width: ${props => props.ru === 'ru' ? '100px' : '110px'};
        height: ${props => props.ru === 'ru' ? '35px' : '100%'};
        
          :nth-child(3) {
            margin-top: ${props => props.ru === 'ru' ? "-40px" : "0px"};
            
            :hover {
              border-bottom: 2px solid #5e5e5e;
            }
          }
      }
    }

    @media (max-width: 630px) {
        display: ${props => props.withBurger ? 'block' : 'none'};
    }
`
export const NavigationListItem = styled.li`
    display: flex;
    justify-content: center;
    padding-top: ${props => props.ru === 'ru' ? '15px' : '0'};
    border-bottom: 2px solid transparent;
    
    
    a {
        text-decoration: none;
        color: inherit;
        width: 110px;
      
        @media(min-width: 630px) {
            text-align:center;
        }
    }

    @media (max-width: 780px) {
        margin: 0 15px;
        width:90px;
    }

    @media (max-width: 630px) {
        margin:0 0 11px 5vh;
        height: 45px;
        width: 40%;
        display: ${props => props.dnone ? 'none' : 'flex'};
        padding-top: 0;
        justify-content: left;
      
        a {
            width:100%;
        }
    }
`
export const Logo = styled(Img)`
  @media (min-width: 631px) {
    margin-top: 15px;
  }
`
export const SmallLogo = styled.div`
    display: none;

    @media (max-width: 630px) {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }
`

export const ImgWrapper = styled.div`
  position: absolute !important;
  left: calc(50% - 60px);
  bottom: -32px;
  width: 120px;
  height: 32px;
`

export const StyledBurger = styled.button`
    display:none;

    @media (max-width: 630px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        right:20px;
        top:30px;
        cursor: pointer;
        padding:8px 10px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        border: 1px solid #edd0a1;
        background: transparent;
        position:absolute;
        z-index:1;

        :focus{
            outline:none;
        }
    }
`

export const Box = styled.div`
    display: flex;
    width: 30px;
    height: 3px;
    margin-bottom: 2px;
    border: none;
    margin-top: 3px;
    background-color: #edd0a1;
    z-index: 2;
`
