import styled from 'styled-components'

export const LocationWrapper = styled.section`
  height: 100%;
  margin-top: 40px;
  padding-bottom: 55px;
  @media(min-width: 1750px){
    margin-top: 10px;
    padding-bottom: 65px;
  }
  @media(max-width: 1000px){
    margin-top: 0;
    padding-bottom: 30px
  }
`

export const AboutLocationWrapper = styled.div`
  display: grid;
  grid-template-rows: 110px 1fr;
  grid-template-columns: 5% 6fr 5fr 2%;
  grid-gap: 30px;
  
  @media (min-width: 1750px){
    grid-template-columns: 10% 6fr 5fr 3%;
    grid-template-rows: 170px 1fr;
  }
  
  @media (max-width: 1350px){
    grid-template-columns: 2% 6fr 6fr 1%;
    grid-template-rows: 91px 1fr;
    grid-gap: 20px;
  }
  
  @media(max-width: 1000px){
    grid-template-columns: 5% 1fr 5%;
    grid-template-rows: 91px 1fr;
    grid-gap: 10px;
  }
`

export const AboutLocation = styled.p`
  font-size: 23px;
  font-family: 'Raleway';
  color: #fff;
  max-width: 700px;
  grid-column: 2/3;
  grid-row: 2;
  
  @media (min-width: 1750px){
    max-width: 900px;
    font-size: 25px;
  }
  
  @media (max-width: 1350px){
    font-size: 21px;
  }

  @media(max-width: 1000px){
    max-width: 1000px;
  }
`
export const LocationTitle = styled.h1`
  font-size: 60px;
  grid-column: 2/3;
  grid-row: 1;

  @media (min-width: 1750px){
    font-size: 75px;
  }
  @media (max-width: 1350px){
    font-size: 50px;
  }
`
export const MapImg = styled.div`
  grid-column: 3/4;
  grid-row: 1 / span 2;
  max-width:880px;
  max-height: 748px;

  @media(max-width:1000px){
    display:none;
  }
`
export const MapLink = styled.a`
  text-decoration: none;
  font-size: ${props => props.ru === 'ru' ? '25px' : '40px'};
  font-family: ${props => props.ru === 'ru' ? 'Raleway' : 'Italianno'};
  display:flex;
  margin-top:50px;
  
  :hover {
    text-decoration:underline;
  }
  
  @media (min-width: 1750px){
    margin-top: 80px;
    font-size: ${props => props.ru === 'ru' ? '32px' : '45px'};
    text-align: center;
  }
`
