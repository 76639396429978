import React from 'react'
import { StaticImage } from "gatsby-plugin-image";
import {StyledWrapper, ReviewHeader, ReviewDescription, FlexWrapper, BookingRating, ReviewerName} from "../../assets/styles";

export const Review = ({review}) => {
  const { sourceImg, name, description } = review

  return (
    <StyledWrapper>
      <ReviewHeader>
        <FlexWrapper>
          {sourceImg === 'google' ?
            <StaticImage quality={100} width={33} height={33} src='../../assets/images/googleLogo.png' alt="google logo"/> :
            <StaticImage quality={100} width={33} height={33} src='../../assets/images/bookingLogo.png' alt="booking logo"/>}
          <ReviewerName>{name}</ReviewerName>
        </FlexWrapper>
        {sourceImg === 'google' ?
        <StaticImage quality={100} width={88} height={15} src='../../assets/images/rating.png' alt="rating"/>
          : <BookingRating>10</BookingRating>}
      </ReviewHeader>

      <ReviewDescription>{description}</ReviewDescription>
    </StyledWrapper>
  )
}
