import React from 'react'
import { SectionHeading, KpMapWrapper } from '../../assets/styles'
import { useIntl } from "gatsby-plugin-intl";
import {StaticImage} from "gatsby-plugin-image";

export const RouteToSaltMine = () => {
    const intl = useIntl();

    return(
        <>
          <SectionHeading>{intl.formatMessage({ id: "recommended_route" })}</SectionHeading>
          <KpMapWrapper>
            <StaticImage
              quality={100}
              width={1800}
              src='../../assets/images/kpmap.jpg'
              alt="KP map"
            />
          </KpMapWrapper>
        </>
    )
}