export const reviewsList = [
  {
    sourceImg: 'booking',
    name: 'Anna',
    description: `Jesteśmy bardzo zadowoleni z pobytu w obiekcie KP Claudia.
      Pokój ładny i nowocześnie urządzony, z balkonem (w oknie moskitiera) i łazienką (z prysznicem, suszarką do włosów i zestawem kosmetyków dla gości). W pokoju znajduje się duże i bardzo wygodne łóżko, fotel, telewizor z dostępem do Netflixa, mała lodówka, czajnik, klimatyzacja i napoje dla gości. Obiekt położony jest w centrum Wieliczki, blisko do kopalni soli, rynku, stacji PKP, sklepów, restauracji, itp. W obiekcie znajduje się ogólnie dostępna kuchnia w pełni wyposażona. Bardzo dobry kontakt z właścicielem. Zostaliśmy oprowadzeni po obiekcie, poinformowani co warto zobaczyć i gdzie można dobrze zjeść. Reasumując apartament czysty, wygodny i dobrze wyposażony w świetnej lokalizacji.
      Serdecznie polecamy!`,
  },
  {
    sourceImg: 'booking',
    name: 'Nadolski',
    description: 'Bardzo ładny, komfortowy, elegancki, po remoncie z dużym balkonem pokój, TV z netflixem, klimatyzacja. Łazienka również elegancka z wszystkimi kosmetykami (żel, mydło, szampon, balsam do ciała) ponadto jednorazowy czepek do osłony włosów pod prysznicem, nawet mały zestaw do szycia na wypadek jakiegoś małego problemu😉 Lodówka i czajnik w pokoju, choć kuchnio jadalnia zapewnia podobnie jak pokój wszystko, łącznie z herbatą, kawa, i. t. p. ekspres do kawy na kapsułki z różnymi kawami do wyboru. Ponadto nowa i elegancka zastawa kuchenna... Ogólnie cały obiekt świetnie przygotowany i mogłabym jeszcze dużo pisać... Dodam tylko kilka słów o właścicielu - pomocny, życzliwy i optymistyczny. Wyjeżdżamy regularnie do różnych miast, więc mamy porównanie, pierwszy raz wystawiam 10 punktów, POLECAM w 100%👌stosunek jakości do ceny ⭐⭐⭐⭐⭐'
  },
  {
    sourceImg: 'booking',
    name: 'Frank',
    description: 'Im Rahmen der Abholung unserer Familie aus der Ukraine haben wir uns in diesem Hotel verabredet und getroffen. Sehr liebevoll familiär geführtes kleines Hotel. Neue modern eingerichtete Zimmer mit WLAN, Klimaanlage, gr. TV, Kühlschrank, Dusche, teilw. Balkon. Pikobello sauber. Der Eigentümer kümmert sich persönlich um jeden Wunsch (auch im Vorfeld) und ist wahnsinnig nett. Abgerundet wird es durch ein leckeres und reichhaltiges kleines Frühstücksbuffet. Das Hotel ist klein, daher extrem gemütlich und liegt auch gut. Café, Kiosk, Supermarkt etc. direkt um die Ecke. Wir würden KP Claudia jeder Hotelkette jederzeit vorziehen !! As part of the pick-up of our family from Ukraine, we met each other at this hotel. Very lovingly family-run small hotel. New modern furnished rooms with Wi-Fi, air conditioning, gr. TV, fridge, shower, partly. with balcony. Absolutely clean. The owner personally takes care of every wish (also in advance) and is incredibly friendly. It is rounded off by a delicious and rich small breakfast buffet. The hotel is small, therefore extremely cozy and also well located. Café, kiosk, supermarket etc. just around the corner. We would prefer KP Claudia to any hotel chain at any time!!',
  },
  {
    sourceImg: 'booking',
    name: 'Jelena',
    description: 'Saimnieks Mariusz bija ļoti laipns un viesmīlīgs, sagaidīja mūs uz ielas pie apartamentiem, ierādīja parkinga vietu, dalījās ar informāciju par apkārtni. Apartamenti ir lieliski, viss gaumīgi iekārtots un tīrs. Ir pieejami higiēnas piederumi un fēns. Netflix. Gulta ērta. Istabā ir ledusskapis un tējkanna. Ir pieejama kopējas lietošanas virtuve, kur ir viss nepieciešamais, lai pagatavotu ēdienu. Lieliska vieta, lai dotos apskatīt Veličkas sāls raktuves. Tuvumā restorāni, bāri un veikali. Saimnieks ļoti labi runā angliski.'
  },
  {
    sourceImg: 'google',
    name: 'Gabi',
    description: "We really like the decoration, colors, and good taste when everything was selected. Very comfortable bed, amazing perfume on towels. Soap, shower gel, and shampoo deliciously selected. If you care about details, go to KP Claudia. If you don`t, just go to a hostel (if there is one there), Thanks!!!! Well, nothing to remark as dislikes as everything was better than expected. Good communication via SMS, in English at any time. Owners very helpful for every single question we had (they were a lot!).",
  },
  {
    sourceImg: 'booking',
    name: 'Anna',
    description: 'Die beste Unterkunft, die wir bis jetzt in Polen hatten! Alles perfekt! Wunderschönes, warmes Zimmer, sehr gutes WLAN, top Dusche… Das Frühstück ist mega! (verschiedene Wurst- und Käsesorten, Tomate, Gurke, Radieschen, usw.. Rühreier mit Speck und Zwiebeln, wenn man mag..Joghurt, Müsli, verschiedene Brötchen usw. - Kaffee und Tee den ganzen Tag) Und allem voran: der Gastgeber! Ein ganz toller Mensch! :) vielen Dank für alles! Ich würde jederzeit wieder hier buchen!',
  },
]