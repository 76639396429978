import React from 'react';
import { useIntl } from "gatsby-plugin-intl"
import { FooterNavigationItem, FooterLink, ExternalLink, FooterTitle, FooterContent, BorderDarkFooter,
  FooterContainer, FooterNavigation, FooterContactWrapper, SocialMediaWrapper, FooterLogoWrapper
} from '../../assets/styles';
import {StaticImage} from "gatsby-plugin-image";

export const Footer = () => {
  const intl = useIntl();
  return (
    <>
      <FooterContainer>
        <BorderDarkFooter>
          <StaticImage quality={100} width={120} height={32} src='../../assets/images/border-dark.png' alt="kp claudia logo" />
        </BorderDarkFooter>
        <FooterNavigation ru={intl.locale}>
          <FooterNavigationItem><FooterLink to="/" >{intl.formatMessage({ id: "home_page" })}</FooterLink></FooterNavigationItem>
          <FooterNavigationItem><FooterLink to="/gallery">{intl.formatMessage({ id: "gallery_page" })}</FooterLink></FooterNavigationItem>
          <FooterNavigationItem><FooterLink to="/reservation">{intl.formatMessage({ id: "offer_page" })}</FooterLink></FooterNavigationItem>
          <FooterNavigationItem><FooterLink to="/contact">{intl.formatMessage({ id: "contact_page" })}</FooterLink></FooterNavigationItem>
        </FooterNavigation>
        <FooterContactWrapper>
          <FooterTitle ru={intl.locale}>{intl.formatMessage({ id: "contact_page" })}</FooterTitle>
          <FooterContent>{intl.formatMessage({ id: "email" })}</FooterContent>
          <FooterContent>{intl.formatMessage({ id: "phone_number" })}</FooterContent>
        </FooterContactWrapper>
        <SocialMediaWrapper>
          <FooterTitle ru={intl.locale}>{intl.formatMessage({ id: "find_us" })}</FooterTitle>
          <ExternalLink href="https://www.facebook.com/Kp-Claudia-Pokoje-116900063045662" target="_blank" rel="noopener norefferer">
            <StaticImage style={{marginTop: '20px'}} quality={100} width={37} height={37} src='../../assets/images/fb.png' alt="facebook logo" />
          </ExternalLink>
        </SocialMediaWrapper>
        <FooterLogoWrapper>
          <StaticImage quality={100} width={150} height={150} src='../../assets/images/full_logo.jpg' alt="kp claudia logo" />
        </FooterLogoWrapper>
      </FooterContainer>
      <div style={{display: "flex", justifyContent: "center", backgroundColor: "#fff"}}>
        <StaticImage quality={100} width={900} height={130} src='../../assets/images/pfr.jpg' alt="pfr" />
      </div>
    </>
);
}
