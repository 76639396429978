import React from 'react'
import { Navigation, Footer } from '../components/index'
import GlobalStyle from '../assets/styles/globalStyles'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpleReactLightbox from 'simple-react-lightbox';

const MainLayout = ({ children }) => (
  <>
    <GlobalStyle />
    <Navigation />
    <SimpleReactLightbox>
      {children}
    </SimpleReactLightbox>
    <Footer />
  </>
)

export default MainLayout