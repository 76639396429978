import React from "react"
import { useIntl } from "gatsby-plugin-intl";
import { LocationWrapper, AboutLocationWrapper, AboutLocation, LocationTitle, MapImg, MapLink } from '../../assets/styles'
import {StaticImage} from "gatsby-plugin-image";

export const Location = () => {
    const intl = useIntl();

    return (
        <LocationWrapper>
            <AboutLocationWrapper>
                <LocationTitle>KP Claudia</LocationTitle>
                <AboutLocation>
                    {intl.formatMessage({ id: "locationDescription" })}<br /><br />
                    {intl.formatMessage({ id: "locationDescription2" })}
                <MapLink ru={intl.locale} href="https://www.google.com/maps/place/Apartameny+KP+Claudia+Nocleg+Wieliczka/@49.9849632,20.0606533,19z/data=!4m16!1m7!3m6!1s0x471641623e18d153:0xb8f8f25c78c79950!2sKsi%C4%99dza+Zygmunta+Goliana+4,+32-020+Wieliczka!3b1!8m2!3d49.9848927!4d20.0611865!3m7!1s0x471641da73ab0e1f:0x47851220c7550db2!5m2!4m1!1i2!8m2!3d49.9849888!4d20.0611559" target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "mapUrl" })}</MapLink>
                </AboutLocation>
                <MapImg>
                    <StaticImage quality={100} width={880} height={748} src='../../assets/images/map1.png' alt="kp map" />
                </MapImg>
            </AboutLocationWrapper>
        </LocationWrapper>
    )
}