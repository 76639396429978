import styled from 'styled-components'
import Img from 'gatsby-image';

export const GalleryWrapper = styled.div`
    margin: 60px auto;
    padding: 10px 25px;
    max-width: 2000px;

    @media (max-width: 630px){
        padding: 10px 8px;
    }
`
export const GalleryImg = styled(Img)`
    width:100%;
    height: 100%;
    object-fit:cover;
    background-color: #212121;
    cursor: pointer;
`

export const Gallery = styled.div`
    display:grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-rows: 320px 500px;
    grid-auto-flow: dense;
  
    > * {
      cursor: pointer;
    }

    @media (min-width: 480px){
        &:first-child{
            grid-area: 1 / 1 / span 2 / span 2;
        }
        &:nth-child(3n) {
            grid-column: span 2;
        }
        :last-child{
            grid-column: fill;
        }
    }
    @media(max-width:700px){

    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        row-gap:10px;
        column-gap: 0;
    }
`